import React, { useState, useEffect } from 'react';

import { Box, Grid } from '@mui/material';

import useResponsive from '../../hooks/useResponsive';
import documentService from '../../services/documentService';
import DashboardSectionHeader from './DashboardSectionHeader';
import DashboardQuickActionItem from './DashboardQuickActionItem';
import { DocumentStatusCount } from '../../@types/documents/types';

// ----------------------------------------------------------------------

const DashboardQuickActions: React.FC = () => {
  const isMobile = useResponsive('down', 'sm');
  const [statusCounts, setStatusCounts] = useState<Omit<DocumentStatusCount, 'moduleType'>[]>([]);

  const isIncompleteDocument =
    statusCounts.filter((status) => {
      if (status.count > 0) {
        return status;
      }
    }).length > 0;

  const getStatusCount = async () => {
    const data = await documentService.getStatusCounts();

    setStatusCounts(data);
  };

  useEffect(() => {
    getStatusCount().then();
  }, []);

  return (
    <>
      {isIncompleteDocument && (
        <Box>
          <DashboardSectionHeader localeText="dashboard.quickActions" />

          <Grid container direction={isMobile ? 'column' : 'row'} spacing={3}>
            {statusCounts.map((status) => {
              if (
                status.count > 0 &&
                status.status !== 0 &&
                status.status !== 1 &&
                status.status !== 4
              ) {
                return (
                  <Grid key={`quick-action-${status.status}`} item xs={isMobile ? 12 : 4}>
                    <DashboardQuickActionItem status={status} />
                  </Grid>
                );
              }
            })}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default DashboardQuickActions;
