import React, { useRef, useState } from 'react';

import { Box, IconButton } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid-pro';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import DLXDriveDocumentActionsMenu from '../../sections/dlx-drive/settings/DLXDriveDocumentActionsMenu';

// ----------------------------------------------------------------------

type RenderDocumentActionsFieldProps = {
  row: GridRowModel;
};

const RenderDocumentActionsField: React.FC<RenderDocumentActionsFieldProps> = ({ row }) => {
  const settingsRef = useRef<HTMLElement>(null);
  const [isMenuPopoverOpen, setIsMenuPopoverOpen] = useState<boolean>(false);

  return (
    <>
      <Box ref={settingsRef}>
        <IconButton
          size="small"
          onClick={(event) => {
            event.stopPropagation();

            setIsMenuPopoverOpen(true);
          }}
        >
          <MoreVertIcon fontSize="inherit" />
        </IconButton>
      </Box>

      <DLXDriveDocumentActionsMenu
        isMenuPopoverOpen={isMenuPopoverOpen}
        setIsMenuPopoverOpen={setIsMenuPopoverOpen}
        anchorEl={settingsRef}
        documentId={row.id}
        documentName={row.name}
        documentStatus={row.status}
        actions={row.actions}
      />
    </>
  );
};

export default RenderDocumentActionsField;
