import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';

import { DocumentsRequestProps } from '../types';

// ----------------------------------------------------------------------

export const PAGE_SIZE = 10;

export const INITIAL_STATE = {
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ['actions'],
  },
};

export const INITIAL_REQUEST: DocumentsRequestProps = {
  folderId: null,
  formTemplateId: null,
  filterModel: null,
  sortModel: null,
  paginationModel: {
    pageSize: PAGE_SIZE,
    page: 0,
  },
};
