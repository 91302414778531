import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Menu, Badge, Button, MenuItem, IconButton, Typography } from '@mui/material';

import useLocales from '../../hooks/useLocales';
import { SavedFiltersProps } from './ViewSettings';
import useNotifications from '../../hooks/useNotifications';
import { DocumentsRequestProps } from '../../@types/documents/types';
import viewSettingsService from '../../services/viewSettingsService';

// ----------------------------------------------------------------------

type GridToolbarSavedFilterSelectorProps = {
  savedFilters: SavedFiltersProps[];
  getSavedFilters: () => void;
};

const GridToolbarSavedFilterSelector: React.FC<GridToolbarSavedFilterSelectorProps> = ({
  savedFilters,
  getSavedFilters,
}) => {
  const { t } = useLocales();
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSuccess, enqueueError } = useNotifications();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    filter: SavedFiltersProps
  ) => {
    e.stopPropagation();

    try {
      await viewSettingsService.deleteView(filter.id);

      getSavedFilters();
      enqueueSuccess(
        t('documents.analytics.snackbar.filterDeletedSuccessfully', { filterLabel: filter.label })
      );
    } catch (e) {
      enqueueError(e);
    }
  };

  const handleFilterSelect = (filter: SavedFiltersProps) => {
    if (filter.formName) {
      const selectedFilters: DocumentsRequestProps = JSON.parse(filter.formName);
      const { formTemplateId, paginationModel, filterModel, sortModel } = selectedFilters;

      if (formTemplateId) {
        searchParams.set('formTemplateId', formTemplateId.toString());
        setSearchParams(searchParams);
      }

      if (filterModel) {
        searchParams.set('filterModel', JSON.stringify(filterModel));
        setSearchParams(searchParams);
      }

      if (sortModel) {
        searchParams.set('sortModel', JSON.stringify(sortModel));
        setSearchParams(searchParams);
      }

      if (paginationModel) {
        searchParams.set('paginationModel', JSON.stringify(paginationModel));
        setSearchParams(searchParams);
      }
    }

    handleClose();
  };

  useEffect(() => {
    if (savedFilters.length === 0) {
      handleClose();
    }
  }, [savedFilters]);

  return (
    <>
      <Button
        id="saved-filter-button"
        disabled={savedFilters.length === 0}
        size="small"
        startIcon={
          <Badge color="primary" badgeContent={savedFilters.length}>
            <FavoriteIcon />
          </Badge>
        }
        onClick={handleClick}
        sx={{ opacity: savedFilters.length === 0 ? 0.5 : 1 }}
      >
        {t('documents.analytics.buttons.myFavorites')}
      </Button>

      <Menu id="saved-filter-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {savedFilters.length > 0 &&
          savedFilters.map((filter, index) => (
            <Box
              key={`filter-key-${filter.label}`}
              sx={{
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <MenuItem
                id={`saved-filter-${index}`}
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
                onClick={() => handleFilterSelect(filter)}
              >
                <Typography sx={{ maxWidth: '300px', display: 'inline-block' }} noWrap>
                  {filter.label}
                </Typography>

                <IconButton
                  id="delete-saved-filter"
                  size="small"
                  onClick={(e) => handleDelete(e, filter)}
                >
                  <DeleteForeverIcon fontSize="inherit" />
                </IconButton>
              </MenuItem>
            </Box>
          ))}
      </Menu>
    </>
  );
};

export default GridToolbarSavedFilterSelector;
