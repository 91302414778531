import React from 'react';
import { useNavigate } from 'react-router';

import HomeIcon from '@mui/icons-material/Home';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Link, Button, IconButton, Typography, Breadcrumbs } from '@mui/material';

import PermissionCheck from 'src/components/access-checks/PermissionCheck';

import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import useResponsive from '../../hooks/useResponsive';
import { Permissions } from '../../@types/auth/constants/permissions';
import DLXDriveSkeletonBreadcrumbs from './DLXDriveSkeletonBreadcrumbs';

// ----------------------------------------------------------------

type DLXDriveBreadcrumbsProps = {
  showFolderTree: boolean;
  setIsDrawerOpen: (isOpen: boolean) => void;
};

const DLXDriveBreadcrumbs: React.FC<DLXDriveBreadcrumbsProps> = ({
  showFolderTree,
  setIsDrawerOpen,
}) => {
  const { t } = useLocales();
  const { breadcrumbs, isLoading } = useSelector((state) => state.dlxDrive);
  const isSmallScreen = useResponsive('down', 'sm');

  const navigate = useNavigate();

  const handleBreadcrumbsClick = (id: number) => {
    navigate(`/documents/${id}`);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 2 }}>
      <PermissionCheck permissions={[Permissions.ViewFolderTree]}>
        <Box sx={{ display: showFolderTree ? 'none' : 'block' }}>
          {isSmallScreen && (
            <Box onClick={() => setIsDrawerOpen(!showFolderTree)}>
              <IconButton id="B80E71" size="medium" color="primary">
                <AccountTreeIcon fontSize="small" />
              </IconButton>
            </Box>
          )}

          {!isSmallScreen && (
            <Button
              id="BI66XN"
              variant="contained"
              startIcon={<AccountTreeIcon />}
              onClick={() => setIsDrawerOpen(!showFolderTree)}
            >
              <Typography variant="subtitle2" noWrap>
                {t('documentTree.folderTree')}
              </Typography>
            </Button>
          )}
        </Box>
      </PermissionCheck>

      {!isLoading && (
        <Breadcrumbs
          maxItems={isSmallScreen ? 2 : 8}
          separator={<FiberManualRecordIcon sx={{ width: '6px', height: '6px' }} />}
        >
          {breadcrumbs &&
            breadcrumbs.map((b, i) => (
              <Link
                key={`breadcrumbs-${i}`}
                underline={i !== breadcrumbs.length - 1 ? 'hover' : 'none'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor:
                    i !== breadcrumbs.length - 1 || breadcrumbs.length === 1
                      ? 'pointer'
                      : 'default',
                }}
                color={i !== breadcrumbs.length - 1 ? 'inherit' : 'text.primary'}
                onClick={() => handleBreadcrumbsClick(b.id)}
                id="wPtD3j"
              >
                {i === 0 && <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />}
                {b.name.length > 30 ? `${b.name.slice(0, 27)}...` : b.name}
              </Link>
            ))}
        </Breadcrumbs>
      )}

      {isLoading && <DLXDriveSkeletonBreadcrumbs />}
    </Box>
  );
};

export default DLXDriveBreadcrumbs;
