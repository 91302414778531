import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Dialog,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import useLocales from '../../hooks/useLocales';
import { useSelector } from '../../redux/store';
import { ViewPresetsReqProps } from './ViewSettings';
import useResponsive from '../../hooks/useResponsive';
import useNotifications from '../../hooks/useNotifications';
import viewSettingsService from '../../services/viewSettingsService';
import { DocumentModuleTypes } from '../../@types/shared/enums/documentModuleTypes';

// ----------------------------------------------------------------------

type GridToolbarSaveFilterButtonProps = {
  getSavedFilters: () => void;
};

const GridToolbarSaveFilterButton: React.FC<GridToolbarSaveFilterButtonProps> = ({
  getSavedFilters,
}) => {
  const { t } = useLocales();
  const [searchParams] = useSearchParams();
  const { enqueueSuccess, enqueueError } = useNotifications();
  const [open, setOpen] = useState(false);
  const [filterLabel, setFilterLabel] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useResponsive('down', 'sm');

  const filterModelParams = searchParams.get('filterModel');
  const sortModelParams = searchParams.get('sortModel');
  const formTemplateIdParams = searchParams.get('formTemplateId');

  const { documentsRequest } = useSelector((state) => state.document);

  const isAnySearchParams = !formTemplateIdParams && !filterModelParams && !sortModelParams;

  const MAX_FILTER_LABEL_LENGTH = 100;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const currentFilters = { ...documentsRequest };
    currentFilters.paginationModel = {
      page: 0,
      pageSize: currentFilters.paginationModel.pageSize,
    };

    const model: ViewPresetsReqProps = {
      label: filterLabel,
      formName: JSON.stringify(currentFilters),
      module: DocumentModuleTypes.Standard,
      columns: [
        {
          label: '_',
          id: '_',
          isChecked: false,
        },
      ],
    };

    try {
      setIsLoading(true);

      await viewSettingsService.createView(model);

      enqueueSuccess(t('documents.analytics.snackbar.filterSavedSuccessfully', { filterLabel }));
    } catch (e) {
      enqueueError(e);
    } finally {
      getSavedFilters();
      handleClose();

      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        id="save-filter-button"
        disabled={isAnySearchParams}
        size="small"
        startIcon={<SaveIcon />}
        onClick={handleOpen}
        sx={{ opacity: isAnySearchParams ? 0.5 : 1 }}
      >
        {t('documents.analytics.buttons.addToFavorites')}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('documents.analytics.labels.saveFilterSet')}</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: isMobile ? '90vw' : '400px',
          }}
        >
          <TextField
            id="filter-label"
            label={t('documents.analytics.fields.enterFilterSetName')}
            size="small"
            error={filterLabel.length === 0 || filterLabel.length > MAX_FILTER_LABEL_LENGTH}
            helperText={
              filterLabel.length === 0
                ? t('documents.analytics.errors.nameRequired')
                : filterLabel.length > MAX_FILTER_LABEL_LENGTH
                  ? t('documents.analytics.errors.maxNameLengthReached', {
                      maxLengthName: MAX_FILTER_LABEL_LENGTH,
                    })
                  : ' '
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFilterLabel(event.target.value);
            }}
          />
        </DialogContent>

        <DialogActions>
          <LoadingButton
            onClick={handleClose}
            loading={isLoading}
            variant="outlined"
            type="button"
            color="inherit"
            id="mz5nnxqa"
          >
            {t('shared.buttons.cancel')}
          </LoadingButton>

          <LoadingButton
            onClick={handleSave}
            disabled={filterLabel.length === 0 || filterLabel.length > MAX_FILTER_LABEL_LENGTH}
            loading={isLoading}
            variant="contained"
            id="yd0sown5"
          >
            {t('shared.buttons.save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GridToolbarSaveFilterButton;
