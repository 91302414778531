import { Avatar, Tooltip, AvatarGroup } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-pro';

import { DocumentStatuses } from '../enums/documentStatuses';
import { DocumentColumnType } from '../enums/documentColumnType';
import GetStatusName from '../../../utils/folders/getStatusName';
import RenderNameField from '../../../utils/folders/renderNameField';
import RenderStatusField from '../../../utils/folders/renderStatusField';
import RenderDateTimeField from '../../../utils/folders/renderDateTimeField';
import RenderSignerDetailsField from '../../../utils/folders/renderSignerDetailsField';
import RenderDocumentActionsField from '../../../utils/folders/renderDocumentActionsField';

// ----------------------------------------------------------------------

export const DEFAULT_COLUMNS: GridColDef<GridValidRowModel>[] = [
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'documents-grid--header',
    type: DocumentColumnType.String,
    hideable: false,
    flex: 1,
    minWidth: 250,
    renderCell: (params) => {
      const { extension, name } = params.row;

      return <RenderNameField name={name} extension={extension} />;
    },
  },
  {
    field: 'created',
    headerName: 'Created',
    headerClassName: 'documents-grid--header',
    type: DocumentColumnType.DateTime,
    minWidth: 160,
    valueGetter: (value: string) => new Date(value),
    renderCell: (params) => {
      const { created } = params.row;

      return <RenderDateTimeField date={created} format="dateTime" />;
    },
  },
  {
    field: 'modified',
    headerName: 'Modified',
    headerClassName: 'documents-grid--header',
    type: DocumentColumnType.DateTime,
    width: 160,
    valueGetter: (value: string) => new Date(value),
    renderCell: (params) => {
      const { modified } = params.row;

      return <RenderDateTimeField date={modified} format="dateTime" />;
    },
  },
  {
    field: 'ownerFullName',
    headerName: 'Owner',
    headerClassName: 'documents-grid--header',
    type: DocumentColumnType.String,
    minWidth: 160,
  },
  {
    field: 'status',
    headerName: 'Status',
    headerClassName: 'documents-grid--header',
    type: DocumentColumnType.SingleSelect,
    minWidth: 200,
    valueOptions: [
      {
        value: DocumentStatuses.New,
        label: <GetStatusName status={DocumentStatuses.New} />,
      },
      {
        value: DocumentStatuses.Completed,
        label: <GetStatusName status={DocumentStatuses.Completed} />,
      },
      {
        value: DocumentStatuses.WaitingForOthers,
        label: <GetStatusName status={DocumentStatuses.WaitingForOthers} />,
      },
      {
        value: DocumentStatuses.WaitingForMe,
        label: <GetStatusName status={DocumentStatuses.WaitingForMe} />,
      },
      {
        value: DocumentStatuses.Rejected,
        label: <GetStatusName status={DocumentStatuses.Rejected} />,
      },
      {
        value: DocumentStatuses.Voided,
        label: <GetStatusName status={DocumentStatuses.Voided} />,
      },
      {
        value: DocumentStatuses.WaitingForOthersApprove,
        label: <GetStatusName status={DocumentStatuses.WaitingForOthersApprove} />,
      },
      {
        value: DocumentStatuses.WaitingForMeApprove,
        label: <GetStatusName status={DocumentStatuses.WaitingForMeApprove} />,
      },
      {
        value: DocumentStatuses.WaitingForRelatedDocuments,
        label: <GetStatusName status={DocumentStatuses.WaitingForRelatedDocuments} />,
      },
      {
        value: DocumentStatuses.WaitingForMeToAcknowledge,
        label: <GetStatusName status={DocumentStatuses.WaitingForMeToAcknowledge} />,
      },
      {
        value: DocumentStatuses.WaitingForOthersToAcknowledge,
        label: <GetStatusName status={DocumentStatuses.WaitingForOthersToAcknowledge} />,
      },
    ],
    renderCell: (params) => {
      const { status } = params.row;

      return <RenderStatusField status={status} />;
    },
  },
  {
    field: 'signatures',
    headerName: 'Signatures',
    headerClassName: 'documents-grid--header',
    type: DocumentColumnType.String,
    renderCell: (params) => {
      const { alreadySigned, totalSigners, signerDetails } = params.row;

      return (
        <RenderSignerDetailsField
          signatures={`${alreadySigned}/${totalSigners}`}
          signerDetails={signerDetails}
        />
      );
    },
    sortable: false,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    headerClassName: 'documents-grid--header',
    type: DocumentColumnType.String,
  },
  {
    field: 'tags',
    headerName: 'Tags',
    headerClassName: 'documents-grid--header',
    type: DocumentColumnType.SingleSelect,
    renderCell: (params) => {
      const { tags } = params.row;

      return (
        <AvatarGroup
          spacing="medium"
          max={3}
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {tags.length > 0 &&
            tags.map((tag) => (
              <Tooltip
                title={`${tag.name}`}
                arrow
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -2],
                        },
                      },
                    ],
                  },
                }}
              >
                <Avatar
                  sx={{ width: 24, height: 24, backgroundColor: tag.color, color: '#fff' }}
                  alt={`${tag.name}`}
                >
                  {`${tag.name}`[0].toUpperCase()}
                </Avatar>
              </Tooltip>
            ))}
        </AvatarGroup>
      );
    },
    sortable: false,
    valueOptions: [],
  },
  {
    field: 'actions',
    headerName: '',
    headerClassName: 'documents-grid--header',
    align: 'center',
    type: DocumentColumnType.Number,
    width: 50,
    renderCell: (params) => <RenderDocumentActionsField row={params.row} />,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
  },
];
