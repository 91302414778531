import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
  Box,
  Menu,
  Badge,
  Button,
  Divider,
  MenuItem,
  useTheme,
  IconButton,
  Typography,
} from '@mui/material';

import useLocales from '../../hooks/useLocales';
import { getFormTemplates } from '../../services/dlxDriveService';
import { DLXDriveFormTemplateListType } from '../../@types/dlx-drive/types';

// ----------------------------------------------------------------------

const GridToolbarFormSelector: React.FC = () => {
  const { t } = useLocales();
  const [searchParams, setSearchParams] = useSearchParams();
  const formTemplateIdParams = searchParams.get('formTemplateId');
  const [formTemplatesList, setFormTemplatesList] = useState<DLXDriveFormTemplateListType[]>([]);
  const [selectedFormTemplate, setSelectedFormTemplate] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const getFormTemplatesList = async () => {
    try {
      const formTemplates = await getFormTemplates();

      const newFormTemplatesList: DLXDriveFormTemplateListType[] = [];

      if (formTemplates) {
        Object.keys(formTemplates).forEach((key) => {
          newFormTemplatesList.push({
            name: key,
            forms: formTemplates[key],
          });
        });
      }

      setFormTemplatesList(newFormTemplatesList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenFormListClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRemoveTemplateId = () => {
    searchParams.delete('formTemplateId');
    setSearchParams(searchParams);
    setAnchorEl(null);
  };

  const handleFormListClose = (id: number) => {
    searchParams.set('formTemplateId', JSON.stringify(id));
    setSearchParams(searchParams);
    setAnchorEl(null);
  };

  useEffect(() => {
    for (let i = 0; i < formTemplatesList.length; i++) {
      for (let j = 0; j < formTemplatesList[i].forms.length; j++) {
        if (formTemplatesList[i].forms[j].id === Number(formTemplateIdParams)) {
          setSelectedFormTemplate(formTemplatesList[i].forms[j].name);
        }
      }
    }
  }, [formTemplateIdParams]);

  useEffect(() => {
    getFormTemplatesList().then();
  }, []);

  return (
    <>
      <Button
        id="form-templates-button"
        disabled={formTemplatesList.length === 0}
        size="small"
        startIcon={
          <Badge color="primary" badgeContent={1} invisible={!formTemplateIdParams}>
            <TextSnippetIcon />
          </Badge>
        }
        onClick={handleOpenFormListClick}
      >
        {t('documents.analytics.buttons.documentTemplates')}
      </Button>

      {formTemplatesList.length > 0 && (
        <Menu
          id="form-templates-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
        >
          {formTemplateIdParams && (
            <Box
              sx={{
                width: '300px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <MenuItem
                id={`saved-filter-${selectedFormTemplate}`}
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 2,
                }}
              >
                <Typography sx={{ maxWidth: '300px', display: 'inline-block' }} noWrap>
                  {selectedFormTemplate}
                </Typography>

                <IconButton id="delete-saved-filter" size="small" onClick={handleRemoveTemplateId}>
                  <DeleteForeverIcon fontSize="inherit" />
                </IconButton>
              </MenuItem>
            </Box>
          )}
          {formTemplatesList.map((template, index) => {
            if (template.forms.length > 0) {
              return (
                <Box
                  key={`${template.name}-${index}`}
                  sx={{
                    maxWidth: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Divider sx={{ mx: 1 }}>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ maxWidth: '240px', display: 'inline-block' }}
                      noWrap
                    >
                      {template.name}
                    </Typography>
                  </Divider>
                  {template.forms.map((form) => (
                    <MenuItem
                      key={`${form.id}-${form.name}`}
                      onClick={() => handleFormListClose(form.id)}
                      sx={{
                        backgroundColor:
                          form.name === selectedFormTemplate
                            ? theme.palette.success.lighter
                            : 'inherit',
                      }}
                    >
                      <Typography sx={{ maxWidth: '300px', display: 'inline-block' }} noWrap>
                        {form.name}
                      </Typography>
                    </MenuItem>
                  ))}
                </Box>
              );
            }
          })}
        </Menu>
      )}
    </>
  );
};

export default GridToolbarFormSelector;
