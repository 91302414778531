import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';
import {
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';

import { SavedFiltersProps } from './ViewSettings';
import useNotifications from '../../hooks/useNotifications';
import GridToolbarFormSelector from './GridToolbarFormSelector';
import viewSettingsService from '../../services/viewSettingsService';
import GridToolbarSaveFilterButton from './GridToolbarSaveFilterButton';
import GridToolbarSavedFilterSelector from './GridToolbarSavedFilterSelector';
import { DocumentModuleTypes } from '../../@types/shared/enums/documentModuleTypes';

// ----------------------------------------------------------------------

const DocumentGridToolbar: React.FC = () => {
  const { enqueueError } = useNotifications();
  const [savedFilters, setSavedFilters] = useState<SavedFiltersProps[]>([]);

  const getSavedFilters = async () => {
    try {
      const data: SavedFiltersProps[] = await viewSettingsService.getViews(
        DocumentModuleTypes.Standard
      );

      setSavedFilters(data);
    } catch (e) {
      enqueueError(e);
    }
  };

  useEffect(() => {
    getSavedFilters().then();
  }, []);

  return (
    <GridToolbarContainer>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Box
          sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', flexWrap: 'wrap' }}
        >
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarSavedFilterSelector
            savedFilters={savedFilters}
            getSavedFilters={getSavedFilters}
          />
          <GridToolbarSaveFilterButton getSavedFilters={getSavedFilters} />
          <GridToolbarFormSelector />
          <GridToolbarDensitySelector />
          <GridToolbarExport />
        </Box>

        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
};

export default DocumentGridToolbar;
