import { debounce } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router';

import { Box, Card, Fade, Stack, Drawer, useTheme, Container } from '@mui/material';
import {
  GridColDef,
  DataGridPro,
  GridRowModel,
  ValueOptions,
  GridSortModel,
  useGridApiRef,
  GridFilterModel,
  GridValidRowModel,
  GridPaginationModel,
  GridRowSelectionModel,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';

import useNotifications from 'src/hooks/useNotifications';

import { GRID_LT_LOCALE_TEXT } from 'src/locales/data-grid-locales/lt';
import { GRID_LV_LOCALE_TEXT } from 'src/locales/data-grid-locales/lv';

import Page from '../../components/Page';
import { useSelector } from '../../redux/store';
import useLocales from '../../hooks/useLocales';
import tagService from '../../services/tagService';
import useResponsive from '../../hooks/useResponsive';
import { TagsFilter } from '../../@types/tags/tagsFilter';
import { getFolder } from '../../services/dlxDriveService';
import eventBus, { EventTypes } from '../../utils/eventBus';
import documentService from '../../services/documentService';
import { setCheckedItems } from '../../redux/slices/document';
import DLXDriveTitle from '../../sections/dlx-drive/DLXDriveTitle';
import { DocumentsRequestProps } from '../../@types/documents/types';
import editSearchParams from '../../utils/documents/editSearchParams';
import FolderTree from '../../sections/dlx-drive/folder-tree/FolderTree';
import { GRID_EN_LOCALE_TEXT } from '../../locales/data-grid-locales/en';
import { GRID_EE_LOCALE_TEXT } from '../../locales/data-grid-locales/ee';
import { GRID_RU_LOCALE_TEXT } from '../../locales/data-grid-locales/ru';
import RenderDateTimeField from '../../utils/folders/renderDateTimeField';
import RenderDateRangeField from '../../utils/folders/renderDateRangeField';
import DLXDriveBreadcrumbs from '../../sections/dlx-drive/DLXDriveBreadcrumbs';
import DocumentGridToolbar from '../../sections/documents/DocumentGridToolbar';
import { DEFAULT_COLUMNS } from '../../@types/documents/constants/DefaultColumns';
import DLXDriveFolderActions from '../../sections/dlx-drive/DLXDriveFolderActions';
import DLXDriveDocumentActions from '../../sections/dlx-drive/DLXDriveDocumentActions';
import { PAGE_SIZE, INITIAL_STATE } from '../../@types/documents/constants/InitialValues';
import DLXDriveBulkActionComponent from '../../sections/dlx-drive/DLXDriveBulkActionComponent';
import {
  addBreadcrumbsState,
  addStopLoadingState,
  addSelectedFolderState,
} from '../../redux/slices/dlxDrive';

// ----------------------------------------------------------------

const Folders = () => {
  const { t, currentLang } = useLocales();
  const theme = useTheme();
  const { breadcrumbs, reloadDriveList } = useSelector((state) => state.dlxDrive);
  const [pageTitle, setPageTitle] = useState<string>(t('layout.dlxDrive'));
  const [showFolderTree, setShowFolderTree] = useState<boolean>(() => {
    const showFolderTree = localStorage.getItem('showFolderTree');
    return showFolderTree ? JSON.parse(showFolderTree) : true;
  });
  const { enqueueSuccess, enqueueError } = useNotifications();
  const { folderId } = useParams();
  const navigate = useNavigate();
  const isMobile = useResponsive('only', 'xs');
  const showDrawer = useResponsive('down', 'lg');
  const [folderTreeExpandedItems, setFolderTreeExpandedItems] = useState<string[]>(() => {
    const folderTreeExpandedItems = localStorage.getItem('folderTreeExpandedItems');

    return folderTreeExpandedItems ? JSON.parse(folderTreeExpandedItems) : [];
  });

  // TODO

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const [localeText, setLocaleText] = useState(GRID_EN_LOCALE_TEXT);

  const filterModelParams = searchParams.get('filterModel');
  const sortModelParams = searchParams.get('sortModel');
  const paginationModelParams = searchParams.get('paginationModel');
  const formTemplateIdParams = searchParams.get('formTemplateId');

  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [sortModel, setSortModel] = useState<GridSortModel>();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>();

  const { checkedItems } = useSelector((state) => state.document);

  const [isLoading, setIsLoading] = useState(false);

  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [rowCount, setRowCount] = useState<number>();

  const [defaultColumns, setDefaultColumns] =
    useState<GridColDef<GridValidRowModel>[]>(DEFAULT_COLUMNS);
  const [customColumns, setCustomColumns] = useState<GridColDef<GridValidRowModel>[]>([]);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const [tags, setTags] = useState<ValueOptions[]>([]);

  const apiRef = useGridApiRef();
  const pageSizeOptions = [PAGE_SIZE, 20, 50];
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    extension: false,
    signerDetails: false,
  });

  const hiddenFields = ['id', 'extension', 'signerDetails'];

  const getTogglableColumns = (columns: GridColDef[]) =>
    columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);

  const autosizeOptions = {
    includeHeaders: true,
    includeOutliers: true,
    outliersFactor: 2,
    expand: true,
  };

  const getFolderData = async () => {
    setIsLoading(true);

    try {
      const data = await getFolder(folderId ? Number(folderId) : null);

      const { id, name, actions, prefix, breadcrumbs, color, icon } = data;

      addSelectedFolderState({ id, name, prefix, actions, color, icon });

      addBreadcrumbsState(breadcrumbs);
    } catch (error) {
      if (error instanceof Error) {
        enqueueError(error.message);
      }
    } finally {
      addStopLoadingState();
      setIsLoading(false);
    }
  };

  const getDocuments = async (request: DocumentsRequestProps) => {
    setIsLoading(true);

    try {
      const response = await documentService.getDocuments(request);

      const { rows, rowCount, columns } = response;

      if (columns.length > 0) {
        const newColumns: GridColDef<GridValidRowModel>[] = [];

        columns.map((column: GridColDef<GridValidRowModel>) => {
          if (column.type === 'date' || column.type === 'dateTime') {
            const newColumnProps = {
              minWidth: 160,
              valueGetter: (value: string) => new Date(value),
              renderCell: (params) => {
                const date = params.row[params.field];

                return (
                  <RenderDateTimeField date={date} format={column.type as 'date' | 'dateTime'} />
                );
              },
            };

            newColumns.push({ ...column, ...newColumnProps });
          } else if (column.type === 'custom') {
            const newColumnProps = {
              minWidth: 160,
              valueGetter: (value: string) => value,
              renderCell: (params) => {
                const dateRange = params.row[params.field];

                if (dateRange) {
                  return <RenderDateRangeField dateRange={dateRange} />;
                }

                return '';
              },
              sortable: false,
              filterable: false,
            };

            newColumns.push({ ...column, ...newColumnProps });
          } else {
            newColumns.push(column);
          }
        });

        setCustomColumns(newColumns);
      } else {
        setCustomColumns(columns);
      }

      setRows(rows);
      setRowCount(rowCount);
    } catch (e) {
      enqueueError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedGetDocuments = useRef(
    debounce(
      async (
        filterModelParams: string | null,
        sortModelParams: string | null,
        paginationModelParams: string | null,
        formTemplateIdParams: string | null,
        folderId: string | undefined
      ) => {
        const documentsRequest = editSearchParams(
          filterModelParams,
          sortModelParams,
          paginationModelParams,
          formTemplateIdParams,
          folderId
        );

        await getDocuments(documentsRequest);
      },
      500
    )
  ).current;

  const getTags = async () => {
    const tagsFilter = new TagsFilter();
    tagsFilter.limit = 999999;
    tagsFilter.offset = 0;
    tagsFilter.searchQuery = null;
    tagsFilter.sortingColumn = null;
    tagsFilter.sortDescending = null;

    const data = await tagService.getPagedTags(tagsFilter);
    const newTags = data.items.map((tag) => ({ label: tag.name, value: tag.id }));

    setTags(newTags);
  };

  // filterModel
  const handleFilterModelChange = (newModel: GridFilterModel) => {
    if (
      newModel.items.length > 0 ||
      (newModel.quickFilterValues && newModel.quickFilterValues.length > 0)
    ) {
      searchParams.set('filterModel', JSON.stringify(newModel));
      setSearchParams(searchParams);
    } else {
      searchParams.delete('filterModel');
      setSearchParams(searchParams);
    }
  };

  // sortModel
  const handleSortModelChange = (newModel: GridSortModel) => {
    if (newModel.length > 0) {
      searchParams.set('sortModel', JSON.stringify(newModel));
      setSearchParams(searchParams);
    } else {
      searchParams.delete('sortModel');
      setSearchParams(searchParams);
    }
  };

  // paginationModel
  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    if (newModel) {
      searchParams.set('paginationModel', JSON.stringify(newModel));
      setSearchParams(searchParams);
    } else {
      searchParams.delete('paginationModel');
      setSearchParams(searchParams);
    }
  };

  // filterModel
  useEffect(() => {
    setFilterModel(filterModelParams ? JSON.parse(filterModelParams) : { items: [] });
  }, [filterModelParams]);

  // sortModel
  useEffect(() => {
    setSortModel(sortModelParams ? JSON.parse(sortModelParams) : []);
  }, [sortModelParams]);

  // paginationModel
  useEffect(() => {
    setPaginationModel(
      paginationModelParams ? JSON.parse(paginationModelParams) : { pageSize: PAGE_SIZE, page: 0 }
    );
  }, [paginationModelParams]);

  // formTemplateId
  useEffect(() => {
    debouncedGetDocuments(
      filterModelParams,
      sortModelParams,
      paginationModelParams,
      formTemplateIdParams,
      folderId
    );
  }, [
    filterModelParams,
    sortModelParams,
    paginationModelParams,
    formTemplateIdParams,
    folderId,
    reloadDriveList,
  ]);

  // location
  useEffect(() => {
    if (location && location.state && location.state.status && location.state.status.filterModel) {
      searchParams.set('filterModel', location.state.status.filterModel);

      setSearchParams(searchParams);
    }
  }, [location]);

  // cancel debounce
  useEffect(
    () => () => {
      debouncedGetDocuments.cancel();
    },
    [debouncedGetDocuments]
  );

  // TODO ^^^

  useEffect(() => {
    if (breadcrumbs.length > 0) {
      const newTitle = `${breadcrumbs[breadcrumbs.length - 1].name} - ${t('layout.dlxDrive')}`;

      if (pageTitle !== newTitle) {
        setPageTitle(newTitle);
      }
    }
  }, [breadcrumbs]);

  useEffect(() => {
    localStorage.setItem('showFolderTree', JSON.stringify(showFolderTree));
  }, [showFolderTree, folderTreeExpandedItems]);

  useEffect(() => {
    localStorage.setItem('folderTreeExpandedItems', JSON.stringify(folderTreeExpandedItems));
  }, [folderTreeExpandedItems]);

  useEffect(() => {
    getFolderData().then();
  }, [folderId]);

  useEffect(() => {
    switch (currentLang.value.toString()) {
      case 'lt':
        setLocaleText(GRID_LT_LOCALE_TEXT);
        break;

      case 'lv':
        setLocaleText(GRID_LV_LOCALE_TEXT);
        break;

      case 'ee':
        setLocaleText(GRID_EE_LOCALE_TEXT);
        break;

      case 'ru':
        setLocaleText(GRID_RU_LOCALE_TEXT);
        break;

      default:
        setLocaleText(GRID_EN_LOCALE_TEXT);
    }
  }, [currentLang]);

  useEffect(() => {
    const newDefaultColumns = defaultColumns.map((column) => {
      if (column.field === 'tags') {
        return {
          ...column,
          valueOptions: tags,
        };
      }

      return column;
    });

    setDefaultColumns(newDefaultColumns);
  }, [tags]);

  useEffect(() => {
    const documentSignedSubscription = eventBus.subscribe(
      EventTypes.DocumentSigned,
      (msg: string) => {
        enqueueSuccess(msg);
      }
    );

    const forbidSignSubscription = eventBus.subscribe(EventTypes.ForbidSign, (msg: string) => {
      enqueueError(msg);
    });

    getTags().then();
    setIsLoading(true);

    return () => {
      documentSignedSubscription.unsubscribe();
      forbidSignSubscription.unsubscribe();
    };
  }, []);

  return (
    <Page title={pageTitle}>
      <Container maxWidth={showFolderTree ? false : 'xl'}>
        <Stack direction="row" spacing={4}>
          {showFolderTree && !showDrawer && (
            <Card sx={{ width: { xl: '25%', lg: '30%' } }}>
              <FolderTree
                showDrawer={showDrawer || false}
                setShowFolderTree={setShowFolderTree}
                folderTreeExpandedItems={folderTreeExpandedItems}
                setFolderTreeExpandedItems={setFolderTreeExpandedItems}
              />
            </Card>
          )}

          <Box
            sx={{
              width: showFolderTree && !showDrawer ? { xl: '75%', lg: '70%' } : '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <DLXDriveBreadcrumbs
              showFolderTree={showFolderTree}
              setIsDrawerOpen={setShowFolderTree}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'left' : 'center',
                gap: 2,
                my: isMobile ? 0 : 0.5,
              }}
            >
              <Box flexGrow={1} sx={{ overflow: 'hidden' }} id="dfODF45">
                <DLXDriveTitle />
              </Box>

              <DLXDriveFolderActions isRootFolder={breadcrumbs.length === 1} />
            </Box>

            {breadcrumbs.length > 1 && folderId && (
              <DLXDriveDocumentActions folderId={Number(folderId)} />
            )}

            <Box
              sx={{
                maxHeight: 'calc(100vh - 273px)',
                width: '100%',
                '& .documents-grid--header': {
                  backgroundColor: theme.palette.background.default,
                },
              }}
            >
              <DataGridPro
                apiRef={apiRef}
                initialState={INITIAL_STATE}
                columns={[...defaultColumns, ...customColumns]}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                rows={rows}
                rowCount={rowCount}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                filterMode="server"
                filterModel={filterModel}
                sortingMode="server"
                sortModel={sortModel}
                resetPageOnSortFilter
                checkboxSelection
                disableRowSelectionOnClick
                onRowClick={(params) => {
                  const { id } = params.row;

                  navigate(`/standard/documents/${id}`);
                }}
                pageSizeOptions={pageSizeOptions}
                slots={{
                  toolbar: DocumentGridToolbar,
                }}
                slotProps={{
                  toolbar: { showQuickFilter: true },
                  loadingOverlay: {
                    variant: 'skeleton',
                    noRowsVariant: 'skeleton',
                  },
                  columnsManagement: {
                    getTogglableColumns,
                  },
                }}
                autosizeOptions={autosizeOptions}
                onPaginationModelChange={(newPaginationModel) => {
                  handlePaginationModelChange(newPaginationModel);
                }}
                onSortModelChange={(newSortModel) => {
                  handleSortModelChange(newSortModel);
                }}
                onFilterModelChange={(newFilterModel) => {
                  handleFilterModelChange(newFilterModel);
                }}
                onRowSelectionModelChange={(newRowSelectionModel, details) => {
                  const newSelectedRows = newRowSelectionModel.map((id) => details.api.getRow(id));

                  setCheckedItems(newSelectedRows);
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                loading={isLoading}
                localeText={localeText}
                unstable_dataSourceCache={null}
              />
            </Box>
          </Box>
        </Stack>

        <Fade in={checkedItems.length > 0} timeout={800}>
          <Box>
            <DLXDriveBulkActionComponent />
          </Box>
        </Fade>
      </Container>

      <Drawer open={showDrawer && showFolderTree} onClose={() => setShowFolderTree(false)}>
        <FolderTree
          showDrawer={showDrawer || false}
          setShowFolderTree={setShowFolderTree}
          folderTreeExpandedItems={folderTreeExpandedItems}
          setFolderTreeExpandedItems={setFolderTreeExpandedItems}
        />
      </Drawer>
    </Page>
  );
};

export default Folders;
