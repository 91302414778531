import axios from 'src/utils/httpClients/axios';
import { mapJsonArray } from 'src/utils/mapUtils';

import { AUTH0_API } from 'src/config';
import { User } from 'src/@types/users/user';
import { Profile } from 'src/@types/users/profile';
import { UserItem } from 'src/@types/users/user-item';
import { UsersFilter } from 'src/@types/users/usersFilter';
import { UserDoclogixManager } from 'src/@types/users/types';
import { DelegationModel } from 'src/@types/users/delegation';
import { UpdateUser } from 'src/@types/users/addressBookUser';
import { PagedResponse } from 'src/@types/shared/pagedResponse';
import { UserProfileImage } from 'src/@types/users/userProfileImage';

import { DocumentsRequestProps } from '../../@types/documents/types';

const baseUrl = `${AUTH0_API.domain}api/users`;

async function getPagedUsers(filter: UsersFilter) {
  const response = await axios.get<PagedResponse<User>>(baseUrl, { params: { ...filter } });

  const data = {
    total: response.data.total,
    items: mapJsonArray(response.data.items, User),
  };

  return data;
}

export async function getProfile() {
  const response = await axios.get<Profile>(`${baseUrl}/profile`);
  return response.data;
}

export async function getDocumentFilter() {
  const response = await axios.get<DocumentsRequestProps[]>(`${baseUrl}/settings`);

  return response.data;
}

export async function setDocumentFilter(model: DocumentsRequestProps[]) {
  await axios.put(`${baseUrl}/settings`, model);
}

async function updateProfile(model: UpdateUser) {
  await axios.put(`${baseUrl}/profile`, model);
}

export async function getProfileImage() {
  const response = await axios.get(`${baseUrl}/profile/image`);
  const data = new UserProfileImage().parseJson(response.data);
  return data;
}

async function setActivation(id: string, isDeactivated: boolean) {
  await axios.put(`${baseUrl}/${id}/set-activation`, { isDeactivated });
}

async function setLocale(locale: string) {
  await axios.put(`${baseUrl}/locale`, locale);
}

async function setUserRoles(id: string, roleIds: string[]) {
  await axios.put(`${baseUrl}/${id}/set-roles`, { roleIds });
}

async function setUserRolesBulk(ids: string[], roleIds: string[]) {
  await axios.put(`${baseUrl}/set-roles-bulk`, { ids, roleIds });
}

async function deleteUser(id: string) {
  await axios.delete(`${baseUrl}/${id}`);
}

async function deleteUsersBulk(ids: string[]) {
  await axios.delete(baseUrl, { data: { ids } });
}

async function getDelegation() {
  const response = await axios.get(`${baseUrl}/own-delegation`);
  const data = new DelegationModel().parseJson(response.data);
  return data;
}

async function setDelegation(id: string, model: DelegationModel) {
  await axios.put(`${baseUrl}/${id}/set-delegation`, model);
}

async function getUserItems(filter: UsersFilter) {
  const response = await axios.get<PagedResponse<UserItem>>(`${baseUrl}/items`, {
    params: { ...filter },
  });

  const data = {
    total: response.data.total,
    items: mapJsonArray(response.data.items, UserItem),
  };

  return data;
}

async function getDoclogixManager() {
  const response = await axios.get<UserDoclogixManager>(`${baseUrl}/own-manager`);
  return response.data;
}

async function setDoclogixManager(request: UserDoclogixManager) {
  await axios.post(`${baseUrl}/own-manager`, request);
}

async function getCeo() {
  const response = await axios.get<User>(`${baseUrl}/ceo`);
  return response.data;
}

const aspUserService = {
  getPagedUsers,
  getProfile,
  updateProfile,
  setActivation,
  setLocale,
  setUserRoles,
  setUserRolesBulk,
  deleteUser,
  deleteUsersBulk,
  getDelegation,
  setDelegation,
  getProfileImage,
  getUserItems,
  getDoclogixManager,
  setDoclogixManager,
  getCeo,
};

export default aspUserService;
