import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Card, useTheme, Typography } from '@mui/material';

import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
import SvgIconStyle from '../../components/SvgIconStyle';
import { getStatusString } from '../../utils/documentUtils';
import { DocumentStatusCount } from '../../@types/documents/types';

// ----------------------------------------------------------------------

type DashboardQuickActionItemProps = {
  status: Omit<DocumentStatusCount, 'moduleType'>;
};

const DashboardQuickActionItem: React.FC<DashboardQuickActionItemProps> = ({ status }) => {
  const { t } = useLocales();
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = () => {
    const newStatus = {
      filterModel: JSON.stringify({
        items: [
          {
            field: 'status',
            operator: 'is',
            value: status.status,
          },
        ],
        quickFilterValues:
          user?.isSuperAdmin || user?.isAdmin || user?.isCeo ? [`${user.email}`] : [],
      }),
    };

    navigate('/documents', { state: { status: newStatus } });
  };

  return (
    <Card sx={{ py: 1.5, px: 2, borderRadius: 2, cursor: 'pointer' }} onClick={handleClick}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SvgIconStyle
          src="/icons/dashboard_quickaction.svg"
          sx={{
            width: 20,
            height: 20,
            flexShrink: 0,
            mr: 1,
            color: theme.palette.primary.dark,
          }}
        />
        <Typography
          variant="subtitle2"
          sx={{
            color: theme.palette.text.secondary,
            textTransform: 'uppercase',
            lineHeight: '1.2rem',
          }}
        >
          {t(getStatusString(status.status))}
        </Typography>

        <Typography variant="subtitle2" sx={{ ml: 1, mr: 1, color: theme.palette.primary.main }}>
          ({status.count})
        </Typography>
      </Box>
    </Card>
  );
};

export default DashboardQuickActionItem;
