import { GridFilterModel } from '@mui/x-data-grid-pro';

import { setDocumentsRequest } from '../../redux/slices/document';
import { DocumentsRequestProps } from '../../@types/documents/types';
import { INITIAL_REQUEST } from '../../@types/documents/constants/InitialValues';

// ----------------------------------------------------------------------

const editSearchParams = (
  filterModelParams: string | null,
  sortModelParams: string | null,
  paginationModelParams: string | null,
  formTemplateIdParams: string | null,
  folderId: string | undefined
) => {
  const newRequest: DocumentsRequestProps = { ...INITIAL_REQUEST };

  // filterModelParams
  if (filterModelParams) {
    const newFilterModel: GridFilterModel = JSON.parse(filterModelParams);

    const allFiltersHasValue = newFilterModel.items.filter((filter) => !filter.value).length === 0;

    if (allFiltersHasValue) {
      newRequest.filterModel = newFilterModel;
    }
  }

  // sortModelParams
  if (sortModelParams) {
    newRequest.sortModel = JSON.parse(sortModelParams);
  }

  // paginationModelParams
  if (paginationModelParams) {
    newRequest.paginationModel = JSON.parse(paginationModelParams);
  }

  // formTemplateIdParams
  if (formTemplateIdParams) {
    newRequest.formTemplateId = Number(formTemplateIdParams);
  }

  // folderId
  if (folderId) {
    newRequest.folderId = Number(folderId);
  }

  setDocumentsRequest(newRequest);

  return newRequest;
};

export default editSearchParams;
