import React, { useState, useEffect } from 'react';

import { LoadingButton } from '@mui/lab';
import { GridColDef, DataGridPro } from '@mui/x-data-grid-pro';
import {
  Dialog,
  TextField,
  IconButton,
  DialogTitle,
  Autocomplete,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import useLocales from '../../../hooks/useLocales';
import { useSelector } from '../../../redux/store';
import useNotifications from '../../../hooks/useNotifications';
import { DLXDriveFolderListItem } from '../../../@types/dlx-drive/types';
import { moveItems, getFolderList } from '../../../services/dlxDriveService';
import { FOLDER_ACTIONS_MENU_ICON_SIZE } from '../../../utils/constants/dlxDriveConstants';
import {
  removeItemState,
  addStopLoadingState,
  setDriveListIdState,
  clearCheckedItemsState,
} from '../../../redux/slices/dlxDrive';

type DLXDriveBulkMoveDialogProps = {
  isBulkMoveDialogOpen: boolean;
  setIsBulkMoveDialogOpen: (value: boolean) => void;
};

// ----------------------------------------------------------------

const DLXDriveBulkMoveDialog: React.FC<DLXDriveBulkMoveDialogProps> = ({
  isBulkMoveDialogOpen,
  setIsBulkMoveDialogOpen,
}) => {
  const { t } = useLocales();
  const { isLoading } = useSelector((state) => state.dlxDrive);
  const { checkedItems } = useSelector((state) => state.document);
  const [selectedFolder, setSelectedFolder] = useState<DLXDriveFolderListItem | undefined>(
    undefined
  );
  const [folderList, setFolderList] = useState<DLXDriveFolderListItem[]>([]);
  const { enqueueSuccess } = useNotifications();

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'id',
      headerName: t('dlxDrive.dataGrid.columnId'),
    },
    {
      field: 'name',
      headerName: t('dlxDrive.dataGrid.columnName'),
      flex: 1,
    },
    {
      field: 'type',
      headerName: t('dlxDrive.dataGrid.columnType'),
    },
    {
      field: 'remove',
      headerName: t('dlxDrive.dataGrid.columnRemove'),
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: (params) => {
        const onClick = () => {
          const { id, name, type } = params.row;

          const itemToRemove = {
            id,
            name,
            isFolder: type === t('dlxDrive.types.folder'),
            actions: [],
          };

          removeItemState(itemToRemove);
        };

        return (
          <IconButton onClick={onClick} id="DjuXCP">
            <img
              src="/icons/dlx-drive/actions/delete.svg"
              width={FOLDER_ACTIONS_MENU_ICON_SIZE}
              height={FOLDER_ACTIONS_MENU_ICON_SIZE}
              alt={t('delete')}
            />
          </IconButton>
        );
      },
    },
  ];

  const rows = checkedItems.map((item) => ({
    id: item.id,
    name: item.name,
    type: item.isFolder
      ? t('dlxDrive.dataGrid.columnTypeFolder')
      : t('dlxDrive.dataGrid.columnTypeDocument'),
  }));

  const handleClose = () => {
    setSelectedFolder(undefined);
    setIsBulkMoveDialogOpen(false);
  };

  const handleMove = async () => {
    try {
      const model = {
        documentIds: checkedItems.filter((item) => !item.isFolder).map((item) => item.id),
        folderIds: checkedItems.filter((item) => item.isFolder).map((item) => item.id),
      };

      if (selectedFolder) {
        await moveItems(selectedFolder.id, model);

        clearCheckedItemsState();

        enqueueSuccess('dlxDrive.notifications.bulkMovedSuccessfully');
        setDriveListIdState();
      }
    } catch (error) {
      console.error(error);
    } finally {
      addStopLoadingState();
      setIsBulkMoveDialogOpen(false);
    }
  };

  const fetchFolderList = async () => {
    let url: string = '';

    checkedItems.forEach((item) => {
      if (item.isFolder) {
        url += `ids=${item.id}&`;
      }
    });

    const folderList = await getFolderList(true, url.length > 0 ? url : undefined);

    const filteredFolderList = folderList.filter((f) => !checkedItems.some((c) => c.id === f.id));

    setFolderList(filteredFolderList);
    addStopLoadingState();
  };

  useEffect(() => {
    if (checkedItems.length === 0) {
      setIsBulkMoveDialogOpen(false);
    }
  }, [checkedItems]);

  useEffect(() => {
    if (isBulkMoveDialogOpen) {
      fetchFolderList();
    }
  }, [isBulkMoveDialogOpen]);

  return (
    <Dialog open={isBulkMoveDialogOpen} fullWidth maxWidth="md" onClose={handleClose}>
      <DialogTitle>{t('dlxDrive.dialogs.bulkDocumentMove')}</DialogTitle>

      <DialogContent>
        <DialogContentText>{t('dlxDrive.dialogs.bulkDocumentMovingInfoText')}</DialogContentText>

        <Autocomplete
          id="move-folders"
          sx={{ my: 2 }}
          disableClearable
          options={folderList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
          groupBy={(option: DLXDriveFolderListItem) => option.firstLetter}
          getOptionLabel={(option) => `${option.name} (id: ${option.id})`}
          onChange={(event: any, newValue: DLXDriveFolderListItem | null) => {
            if (newValue) {
              setSelectedFolder(newValue);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('dlxDrive.dialogs.folderList')}
              placeholder="Favorites"
            />
          )}
        />

        <DataGridPro
          columns={columns}
          rows={rows}
          rowHeight={50}
          sx={{ width: '100%' }}
          disableColumnFilter
          disableColumnMenu
          disableMultipleColumnsSorting
          disableChildrenSorting
          localeText={{ footerTotalRows: t('dlxDrive.dataGrid.footerTotalRows') }}
        />
      </DialogContent>

      <DialogActions sx={{ display: 'flex', justifyContent: 'left', textAlign: 'center', gap: 1 }}>
        <LoadingButton loading={isLoading} variant="outlined" onClick={handleClose} id="mz5nnxqa">
          {`${t('shared.buttons.cancel')}`}
        </LoadingButton>

        <LoadingButton
          disabled={selectedFolder === undefined}
          loading={isLoading}
          variant="contained"
          onClick={handleMove}
          id="yd0sown5"
        >
          {`${t('shared.buttons.move')}`}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DLXDriveBulkMoveDialog;
