import React from 'react';

import { Box } from '@mui/material';

import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------

type RenderDateRangeFieldProps = {
  dateRange: string;
};

const RenderDateRangeField: React.FC<RenderDateRangeFieldProps> = ({ dateRange }) => {
  const { currentLang } = useLocales();
  const dateRangeArr = dateRange.split(',');

  const startDate = new Date(dateRangeArr[0]).toLocaleDateString(currentLang.value);
  const endDate = new Date(dateRangeArr[1]).toLocaleDateString(currentLang.value);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        gap: 1,
      }}
    >
      {`${startDate} - ${endDate}`}
    </Box>
  );
};

export default RenderDateRangeField;
