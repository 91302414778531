import { AxiosResponse } from 'axios';

import axios from 'src/utils/httpClients/axios';

import { HOST_API } from 'src/config';
import { DocumentModuleTypes } from 'src/@types/shared/enums/documentModuleTypes';

import { ViewPresetsReqProps } from 'src/sections/documents/ViewSettings';

const baseUrl = `${HOST_API  }view-presets`;

const createView = async (model: ViewPresetsReqProps) => {
  let response: AxiosResponse<any>;
  response = await axios.post(baseUrl, model);
  return response.data;
};

const getViews = async (module: DocumentModuleTypes) => {
  let response: AxiosResponse<any>;
  response = await axios.get(`${baseUrl  }/my-presets?module=${module}`);
  return response.data;
};

const deleteView = async (id: number) => {
  await axios.delete(`${baseUrl  }/${id}`);
};

const updateView = async (id: number, model: ViewPresetsReqProps) => {
  await axios.put(`${baseUrl  }/${id}`, model);
};

const updateCheckedView = async (id: number, module: DocumentModuleTypes) => {
  await axios.put(`${baseUrl  }/${id}/check?module=${module}`);
};

const viewSettingsService = {
  createView,
  getViews,
  deleteView,
  updateView,
  updateCheckedView,
};

export default viewSettingsService;
